import React, { Component } from "react";
import SadSandwich from "../images/SadSandwich.png";
import HappySandwich from "../images/HappySandwich.png";
import Sushi from "../images/Sushi.png";
import Toast from "../images/Toast.png";
import Taco from "../images/Taco.png";
import Quiche from "../images/Quiche.png";
import Calzone from "../images/Calzone.png";
import Salad from "../images/Salad.png";
import Cake from "../images/Cake.png";
import Nachos from "../images/Nachos.png";
//import { Link } from "react-router-dom";

const AWSHost =
  "https://554lj8cle0.execute-api.us-west-2.amazonaws.com/cuberule";

class Entry extends Component {
  constructor(props) {
    super();

    this.state = {
      foodInput: "",
      category: "",
      newCategory: "toast",
      notFound: false,
      thankYou: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.submitFoodItem = this.submitFoodItem.bind(this);
    this.clear = this.clear.bind(this);
  }

  clear() {
    this.setState({
      category: "",
      foodInput: "",
      notFound: false,
      newCategory: "toast",
      thankYou: false,
    });
  }

  handleChange(e) {
    let target = e.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    let name = target.name;

    this.setState({
      [name]: value.toLowerCase(),
    });
  }

  submitFoodItem(e) {
    e.preventDefault();

    this.setState({ thankYou: true });

    //if no food input provided, error out
    if (this.state.newCategory.length < 1) {
      alert("Please Select a Food Category.");
      return;
    }

    //get current UTC time
    const d = new Date();
    let time = d.getTime();

    //post new food item and category
    fetch(
      AWSHost +
        "/foods?name=" +
        this.state.foodInput +
        "&category=" +
        this.state.newCategory,
      {
        mode: "cors",
        method: "POST",
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          console.log("Food Successfully Added.");
        },
        (error) => {
          console.log(error);
        }
      );
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ category: "", thankYou: false, newCategory: "toast" });

    //if no food input provided, error out
    if (this.state.foodInput.length < 1) {
      alert("Please Enter a Food Item to Check.");
      return;
    }

    //get current UTC time
    const d = new Date();
    let time = d.getTime();

    //check food
    fetch(AWSHost + "/foods?name=" + encodeURIComponent(this.state.foodInput), {
      mode: "cors",
      method: "GET",
    })
      .then((res) => res.json())
      .then(
        (result) => {
          let foodItem = result.body[0];
          if (foodItem) {
            console.log("Food Successfully Identified.");
            this.setState({
              category: foodItem.category.toLowerCase(),
              notFound: false,
            });
          } else {
            console.log("Error: Food Was Not Able to be Identifited.");
            this.setState({ notFound: true });
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  render() {
    return (
      <div style={{ flex: 4 }}>
        {/* <!-- Post --> */}
        <article className="post">
          <header>
            <div className="title">
              <h2>
                <a>Is It A Sandwich?</a>
              </h2>
            </div>
          </header>

          <form onSubmit={this.handleSubmit}>
            <label>Food Item To Test</label>
            <input
              type="text"
              id="foodInput"
              placeholder="Enter possible sandwich here"
              name="foodInput"
              value={this.state.foodInput}
              onChange={this.handleChange}
              style={{ width: "50%" }}
            ></input>
            <br></br>
            <input type="submit" value="Is this a sandwich?"></input>
            <input type="button" value="Clear" onClick={this.clear}></input>
          </form>
        </article>
        {this.state.notFound === true ? (
          //Item not found in database
          <article className="post">
            <div style={{ flexDirection: "row", overflow: "auto" }}>
              {this.state.thankYou ? (
                <div style={{ flex: 1 }}>
                  <h2 style={{ display: "inline-block", marginRight: "1em" }}>
                    <a>Thank You!</a>
                  </h2>
                  <p style={{ display: "inline-block", marginRight: "1em" }}>
                    Your food item was added to our database.
                  </p>
                  <img
                    src={HappySandwich}
                    alt=""
                    style={{
                      marginBottom: "2em",
                      float: "right",
                      width: "10em",
                    }}
                  />
                </div>
              ) : (
                <div>
                  <div style={{ flex: 1 }}>
                    <h2 style={{ display: "inline-block", marginRight: "1em" }}>
                      <a>Sorry!</a>
                    </h2>
                    <p style={{ display: "inline-block", marginRight: "1em" }}>
                      Your food item is not in our database.
                    </p>
                    <img
                      src={SadSandwich}
                      alt=""
                      style={{
                        marginBottom: "2em",
                        float: "right",
                        width: "10em",
                      }}
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <form onSubmit={this.submitFoodItem}>
                      <label>
                        Help us identify more food items! What category does
                        your food fall into? <br></br>(use the starch location
                        matrix on the left!)
                      </label>
                      <select
                        name="newCategory"
                        id="newCategory"
                        value={this.state.newCategory}
                        onChange={this.handleChange}
                        style={{ width: "50%" }}
                      >
                        <option value="toast">Toast</option>
                        <option value="sandwich">Sandwich</option>
                        <option value="taco">Taco</option>
                        <option value="sushi">Sushi</option>
                        <option value="quiche">Quiche</option>
                        <option value="calzone">Calzone</option>
                        <option value="salad">Salad</option>
                        <option value="cake">Cake</option>
                        <option value="nachos">Nachos</option>
                      </select>
                      <br></br>
                      <input type="submit" value="Submit"></input>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </article>
        ) : (
          ""
        )}

        {this.state.category === "" ? (
          ""
        ) : (
          <article className="post">
            <div style={{ flexDirection: "row", overflow: "auto" }}>
              {this.state.category === "sandwich" ? (
                <div style={{ flex: 1 }}>
                  <h2 style={{ display: "inline-block", marginRight: "1em" }}>
                    <a>Yes!</a>
                  </h2>
                  <p style={{ display: "inline-block", marginRight: "1em" }}>
                    This is a{" "}
                  </p>
                  <h2 style={{ display: "inline-block" }}>
                    <a>Sandwich!</a>
                  </h2>
                  <img
                    src={HappySandwich}
                    alt=""
                    style={{
                      marginBottom: "2em",
                      float: "right",
                      width: "10em",
                    }}
                  />
                </div>
              ) : (
                <div style={{ flex: 1 }}>
                  <h2 style={{ display: "inline-block", marginRight: "1em" }}>
                    <a>No!</a>
                  </h2>
                  <p style={{ display: "inline-block", marginRight: "1em" }}>
                    This is not a Sandwich, but it is a type of{" "}
                  </p>
                  <h2 style={{ display: "inline-block" }}>
                    <a>{this.state.category}!</a>
                  </h2>
                  <img
                    src={
                      this.state.category === "toast"
                        ? Toast
                        : this.state.category === "taco"
                        ? Taco
                        : this.state.category === "sushi"
                        ? Sushi
                        : this.state.category === "quiche"
                        ? Quiche
                        : this.state.category === "calzone"
                        ? Calzone
                        : this.state.category === "salad"
                        ? Salad
                        : this.state.category === "cake"
                        ? Cake
                        : this.state.category === "nachos"
                        ? Nachos
                        : SadSandwich
                    }
                    alt=""
                    style={{
                      marginBottom: "2em",
                      float: "right",
                      width: "10em",
                    }}
                  />
                </div>
              )}
            </div>
          </article>
        )}
      </div>
    );
  }
}

export default Entry;
