import React, { Component } from "react";
import Avatar from "./images/CubeRule.png";
import GreenFlag from "./images/GreenFlag.png";
import RedFlag from "./images/RedFlag.png";
import { HashRouter as Router, Route, NavLink } from "react-router-dom";
import "./assets/css/main.css";
import Confetti from "react-confetti";

import Entry from "./components/Entry";

import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously } from "firebase/auth";
import { getDatabase, ref, set, onValue, child, get } from "firebase/database";

const AWSHost =
  "https://554lj8cle0.execute-api.us-west-2.amazonaws.com/cuberule";

const firebaseConfig = {
  apiKey: "AIzaSyD8r7EYKoQh4oyzK8B7YH7ErOtnYLpgz-g",
  authDomain: "cuberule.firebaseapp.com",
  databaseURL: "https://cuberule-default-rtdb.firebaseio.com",
  projectId: "cuberule",
  storageBucket: "cuberule.appspot.com",
  messagingSenderId: "1095644624718",
  appId: "1:1095644624718:web:3c97da10ea832a43fb101f",
  measurementId: "G-18V1DHGC3Y",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth();
const database = getDatabase(app);

class App extends Component {
  constructor() {
    super();

    this.state = {
      userAuthenticated: false,
      createAccount: false,
      email: "",
      password: "",
      favoriteFood: "",
      leaderboardName: "",
      time: 0,
      scores: null,
      startTime: new Date(),
    };

    this.logOut = this.logOut.bind(this);
    this.createAccount = this.createAccount.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.submitScore = this.submitScore.bind(this);
  }

  componentDidMount() {
    document.title = "The Cube Rule Database";

    this.setState({ startTime: new Date() });

    const dbRef = ref(getDatabase());
    get(child(dbRef, "leaderboard"))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const snap = snapshot.val();
          const sorted = Object.entries(snap).sort((a, b) =>
            a[1].time > b[1].time ? 1 : -1
          );
          this.setState({ scores: sorted });
        } else {
          //console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });

    onValue(ref(database, "leaderboard"), (snapshot) => {
      const snap = snapshot.val();
      const sorted = Object.entries(snap).sort((a, b) =>
        a[1].time > b[1].time ? 1 : -1
      );
      this.setState({ scores: sorted });
    });
  }

  submitScore(e) {
    e.preventDefault();

    signInAnonymously(auth)
      .then((event) => {
        // Signed in
        const uid = event.user.uid;
        set(ref(database, "leaderboard/" + uid), {
          username: this.state.leaderboardName,
          time: this.state.time,
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorMessage);
      });
  }

  logOut() {
    this.setState({ userAuthenticated: false });
  }

  createAccount() {
    this.setState({ createAccount: !this.state.createAccount });
  }

  handleChange(e) {
    let target = e.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    let name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    //if no email address provided, error out
    if (this.state.email.length < 1 || this.state.password.length < 1) {
      alert("Please enter an email address and password.");
      return;
    }

    //get current UTC time
    const d = new Date();
    let time = d.getTime();

    if (this.state.createAccount) {
      if (this.state.email === "admin@cuberuledatabase.com") {
        alert("This Email Address is taken.");
        return;
      }
      //create new user
      fetch(
        AWSHost +
          "/user?email=" +
          this.state.email +
          "&password=" +
          this.state.password +
          "&favoriteFood=" +
          this.state.favoriteFood +
          "&authorizationToken=eihvo9H1heVLmGwLMBkQ",
        {
          mode: "cors",
          method: "POST",
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            console.log("User Account Created");
          },
          (error) => {
            console.log(error);
          }
        );

      this.setState({ createAccount: false, userAuthenticated: true });
    } else {
      //check if valid user
      fetch(
        AWSHost +
          "/user?email=" +
          encodeURIComponent(this.state.email) +
          "&password=" +
          this.state.password +
          "&authorizationToken=eihvo9H1heVLmGwLMBkQ",
        {
          mode: "cors",
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            let user = result.body;
            if (
              user &&
              user.authorized === true
              // user.email === this.state.email &&
              // user.password === this.state.password
            ) {
              if (this.state.email === "admin@cuberuledatabase.com") {
                let time =
                  (new Date().getTime() - this.state.startTime.getTime()) /
                  1000;
                this.setState({ time: time });
              }
              console.log("User Authenticated.");
              this.setState({ userAuthenticated: true });
            } else {
              alert(
                "Either no account was found with this email address or your password was incorrect."
              );
            }
          },
          (error) => {
            //setIsLoaded(true);
            console.log(error);
          }
        );
    }
  }

  render() {
    return (
      <Router>
        <div id="wrapper">
          {this.state.userAuthenticated ? (
            ""
          ) : this.state.createAccount ? (
            //Create Account
            <div style={{ flex: 4 }}>
              <article className="post">
                <header>
                  <div className="title">
                    <h2>
                      <a>Create Account</a>
                    </h2>
                  </div>
                </header>
                <form onSubmit={this.handleSubmit}>
                  <h3 style={{ color: "red" }}>
                    NOTE: This App is insecure, DO NOT provide real credentials
                  </h3>
                  <label>Email</label>
                  <input
                    type="email"
                    id="email"
                    placeholder="Enter your email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    style={{ width: "50%" }}
                  ></input>
                  <br></br>
                  <label>Password</label>
                  <input
                    type="password"
                    id="password"
                    placeholder="Enter your password"
                    name="password"
                    value={this.state.password}
                    onChange={this.handleChange}
                    style={{ width: "50%" }}
                  ></input>
                  <br></br>
                  <label>Favorite Food</label>
                  <input
                    type="text"
                    id="favoriteFood"
                    placeholder="What's your favorite food?"
                    name="favoriteFood"
                    value={this.state.favoriteFood}
                    onChange={this.handleChange}
                    style={{ width: "50%" }}
                  ></input>
                  <br></br>

                  <input
                    type="submit"
                    value="Sign Up"
                    style={{ marginRight: "20px" }}
                  ></input>
                  <input
                    type="button"
                    value="Have an account already? Login"
                    onClick={this.createAccount}
                  ></input>
                </form>
              </article>
            </div>
          ) : (
            //Login
            <div style={{ flex: 4 }}>
              <article className="post">
                <header>
                  <div className="title">
                    <h2>
                      <a>Login</a>
                    </h2>
                    <p>Identify any food item by its starch location!</p>
                  </div>
                </header>
                <form onSubmit={this.handleSubmit}>
                  <label>Email</label>
                  <input
                    type="email"
                    id="email"
                    placeholder="Enter your email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    style={{ width: "50%" }}
                  ></input>
                  <br></br>
                  <label>Password</label>
                  <input
                    type="password"
                    id="password"
                    placeholder="Enter your password"
                    name="password"
                    value={this.state.password}
                    onChange={this.handleChange}
                    style={{ width: "50%" }}
                  ></input>
                  <br></br>

                  <input
                    type="submit"
                    value="Login"
                    style={{ marginRight: "20px" }}
                  ></input>
                  <input
                    type="button"
                    value="Create New Account"
                    onClick={this.createAccount}
                  ></input>
                </form>
                <p style={{ color: "firebrick" }}>
                  CTF Rules:<br></br>
                  1. If you are reading me, Keep this browser tab open! don't
                  close it or your score will not be counted on the leaderboard.
                  You can still switch to other tabs.
                  <br></br>
                  2. Your goal is to gain privledged access to this web app and
                  obtain a secure flag, there are several solutions. We run on
                  the honor system, no cheating!<br></br>
                  3. Good Luck!... Hmm, I seem to have misplaced a green flag.
                  Could be near something helpful, oh well.
                </p>
              </article>
            </div>
          )}
          {this.state.userAuthenticated ? (
            this.state.email === "admin@cuberuledatabase.com" ? (
              <div style={{ flex: 4 }}>
                <Confetti
                  width={window.innerWidth}
                  height={window.innerHeight}
                />
                <article className="post">
                  <header>
                    <div className="title">
                      <h2 style={{ textAlign: "center" }}>
                        <a>Welcome Admin!</a>
                        <br></br>
                        <a>Flag Captured!</a>
                      </h2>
                      <img
                        src={RedFlag}
                        align="middle"
                        alt=""
                        style={{
                          width: "10em",
                          display: "block",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      />
                    </div>
                  </header>
                  <div>
                    <h2>
                      <a>Submit Your Time!</a>
                    </h2>
                    <p>Please Keep it honest, no cheating the leaderboard!</p>
                    <form onSubmit={this.submitScore}>
                      <table>
                        <tbody>
                          <tr>
                            <td>Your Time</td>
                            <td>Enter Your Username</td>
                          </tr>
                          <tr>
                            <td>{this.state.time}</td>
                            <td>
                              <div style={{ display: "flex" }}>
                                <input
                                  type="text"
                                  id="leaderboardName"
                                  placeholder="username"
                                  name="leaderboardName"
                                  value={this.state.leaderboardName}
                                  onChange={this.handleChange}
                                  style={{ width: "50%", marginRight: "2em" }}
                                ></input>
                                <input type="submit" value="Submit"></input>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </form>

                    <div>
                      <h3
                        style={{
                          textAlign: "center",
                        }}
                      >
                        Leaderboard
                      </h3>
                      <table className="content-table">
                        <thead>
                          <tr>
                            <th>Rank</th>
                            <th>Name</th>
                            <th>Time</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.scores !== null ? (
                            this.state.scores.map((item, i) => (
                              <tr key={item[0]}>
                                <td>{i + 1}</td>
                                <td>{item[1].username}</td>
                                <td>{item[1].time}</td>
                              </tr>
                            ))
                          ) : (
                            <tr></tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </article>
              </div>
            ) : (
              <Route path="/" component={Entry} />
            )
          ) : (
            ""
          )}

          {/* <!-- Header --> */}
          <header id="header">
            <h1>
              <a href="index.html">The Cube Rule Database</a>
            </h1>
            <nav className="links">
              <ul>
                {this.state.userAuthenticated ? (
                  <li>
                    <NavLink to="/">Food Item Entry</NavLink>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </nav>
            <nav className="main">
              {this.state.userAuthenticated ? (
                <button
                  onClick={this.logOut}
                  style={{ height: "100%", boxShadow: "0px", display: "block" }}
                >
                  Log Out
                </button>
              ) : (
                ""
              )}
            </nav>
          </header>

          {/* <!-- Sidebar --> */}
          <section id="sidebar" style={{ flex: 1 }}>
            {/* <!-- Intro --> */}
            <section id="intro">
              {/* <a className="logo"> */}
              <h2 style={{ textAlign: "center" }}>Cube Rule Matrix</h2>
              <img
                src={Avatar}
                alt=""
                style={{
                  width: "20em",
                }}
              />
            </section>
            <section>
              <header>
                <p>Want To Learn More?</p>
                <a
                  href="https://www.cuberule.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button"
                >
                  The Cube Rule
                </a>
              </header>
            </section>

            {/* <!-- Footer --> */}
            <section id="footer">
              <ul className="icons">
                <li>
                  <a
                    href="https://twitter.com"
                    className="fa-twitter"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="label">Twitter</span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com"
                    className="fa-facebook"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="label">Facebook</span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com"
                    className="fa-instagram"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="label">Instagram</span>
                  </a>
                </li>
                <li>
                  <a
                    href="mailto:admin@cuberuledatabase.com"
                    className="fa-envelope"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="label">Email</span>
                  </a>
                </li>
                <li>
                  <img
                    src={GreenFlag}
                    alt=""
                    style={{
                      width: "1.5em",
                      height: "1.5em",
                      verticalAlign: "text-bottom",
                    }}
                  />
                </li>
              </ul>
              <p className="copyright">
                {/* &copy; */}
                The Cube Rule Database 2021{" "}
                <a
                  href="https://www.CubeRuleDatabase.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  CubeRuleDatabase.com
                </a>
              </p>
            </section>
          </section>
        </div>
      </Router>
    );
  }
}

export default App;
